const bootstrap = {
  button: 'btn',
  buttonGroup: 'btn-group',
  centerBlock: 'center-block',
  danger: 'danger',
  default: 'default',
  defaultLabel: 'label label-default',
  error: 'error',
  formInline: 'form-inline',
  h4: 'h4',
  hasError: 'has-error',
  hide: 'hide',
  inlineBlock: 'inline-block',
  lightButton: 'btn-light',
  primary: 'primary',
  primaryButton: 'btn btn-primary',
  pullLeft: 'pull-left',
  pullRight: 'pull-right',
  pullRightLarge: 'pull-right-lg',
  relative: 'relative',
  secondaryButton: 'btn-default',
  success: 'success',
  textCenter: 'text-center',
  textDanger: 'text-danger',
  textLeft: 'text-left',
  textRight: 'text-right',
  warning: 'warning',
  well: 'well',
  wrapWord: 'wrap-word',
  multilineEllispes: 'line-clamp',
  wrapWordBreak: 'wrap-word-break'
};

const circleSpinner = {
  c1: 'circle1',
  c2: 'circle2',
  c3: 'circle3',
  c4: 'circle4',
  c5: 'circle5',
  c6: 'circle6',
  c7: 'circle7',
  c8: 'circle8',
  c9: 'circle9',
  c10: 'circle10',
  c11: 'circle11',
  c12: 'circle12',
  circle: 'circle',
  container: 'spinner-container',
  spinnerWrapper: 'spinner-wrapper',
  spinnerWrapperBig: 'spinner-wrapper_big',
  spinnerWrapperFixed: 'spinner-wrapper_fixed',
  wrapper: 'circle-wrapper',
  spinnerSpaceContainer: 'spinner-space-container'
};

const forms = {
  formGroupMultiSelect: 'form-group-multi-select',
  formGroupViewMode: 'form-group-view-mode',
  formGroupViewModeClickable: 'form-group-view-mode-clickable',
  formGroupViewModeNonClickable: 'form-group-view-mode-non-clickable',
  formGroupViewModeInline: 'form-group-view-mode-inline',
  formGroupViewModeMultiline: 'form-group-view-mode-multiline',
  formViewModeSection: 'form-view-mode-section'
};

const icons = {
  leftArrow: 'fas fa-arrow-left',
  add: 'fa fa-plus',
  addressLight: 'fal fa-address-card',
  addressSolid: 'fas fa-address-card',
  checked: 'fa fa-check-square',
  clock: 'fa fa-clock-o',
  dashboard: 'fas fa-tachometer-alt-fast',
  delete: 'fa fa-trash',
  document: 'fa fa-file-o',
  downArrow: 'fa fa-arrow-circle-o-down',
  download: 'fa fa-download',
  edit: 'fa fa-pencil',
  remove: 'fa fa-close',
  email: 'fa fa-envelope-o',
  envelope: 'fa fa-envelope',
  user: 'fa fa-user',
  users: 'fa fa-users',
  userPlus: 'fa fa-user-plus',
  favouritePosition: 'favourite-position',
  favouritePositionTablet: 'favourite-position-tablet',
  filter: 'fa fa-filter',
  iconSpaceLeft: 'icon-space-left',
  iconSpaceRight: 'icon-space-right',
  large: 'fa-lg',
  small: 'fa-sm',
  location: 'fa fa-map-marker',
  noSelection: 'no-selection',
  phone: 'fa fa-phone',
  unchecked: 'fa fa-square-o',
  upload: 'fa fa-photo',
  website: 'fa fa-globe',
  globeAmerica: 'fa fa-globe-americas',
  uploadDocument: 'fa fa-upload',
  openLink: 'fas fa-external-link-alt',
  downloadFile: 'fas fa-download',
  backArrow: 'fa fa-chevron-left',
  circleArrowUp: 'fas fa-arrow-alt-circle-up',
  parentOrganisation: 'fa fa-sitemap',
  arrowDown: 'fa fa-angle-down',
  shop: 'fa fa-shopping-cart',
  inviteManager: 'fas fa-ticket-alt',
  badge: 'fas fa-id-card-alt',
  exclamationTriangle: 'fas fa-exclamation-triangle',
  dropDown: 'fa fa-caret-down',
  settings: 'fas fa-cog',
  warningTriangle: 'fas fa-exclamation-triangle'
};

const switchControl = {
  slider: 'slider',
  sliderSmall: 'slider-small',
  slidingPanel: 'sliding-panel',
  slidingPanelSmall: 'sliding-panel-small',
  switch: 'switch',
  inlineSwitch: 'inline-switch',
  switchCheckbox: 'switch-checkbox',
  switchInner: 'switch-inner',
  switchInnerAfter: 'switch-inner-after',
  switchInnerBefore: 'switch-inner-before',
  switchLabel: 'switch-label',
  switchSmall: 'switch-small'
};

const generic = {
  hiddenMobileView: 'hidden-xs',
  visibleMobileView: 'visible-xs',
  alignTextToLogo: 'align-text-to-logo',
  alignSessionDate: 'align-session-date',
  alignDate: 'align-date',
  alignDescription: 'align-description',
  alignSocialMedia: 'align-social-media',
  centerFlex: 'center-flex',
  clearfix: 'clearfix',
  clickable: 'clickable',
  nonClickable: 'non-clickable',
  contactUsFill: 'showplan--svg-fill-icon__btnFontColor',
  cursorNotAllowed: 'cursor-not-allowed',
  centerAlign: 'center-align',
  rightAlign: 'text-align-right',
  displayFlex: 'display-flex',
  displayStandsLaptop: 'display-stands-laptop',
  displayStandsTablet: 'display-stands-tablet',
  displayStands: 'display-stands',
  documentSection: 'document-section',
  eventEditionLabel: 'event-edition-label',
  facebookLogo: 'facebook-logo',
  favouriteIcon: 'favourite-icon',
  callToActionIcon: 'call-to-action-icon',
  exhibitorDetailsInfo: 'exhibitor-details-info',
  requestQuoteBtn: 'request-quote-btn',
  viewAllProducts: 'view-all-products',
  requestButton: 'request-button',
  requestButtonIcon: 'request-button-icon',
  bulkRequestQuoteLimitError: 'bulk-request-quote-limit-error',
  genericIcon: 'generic-icon',
  ExhibitorDetailIcons: 'exhibitor-detail-icons ',
  tableData: 'table-td',
  favouriteTabletHidden: 'favourite-tablet-hidden',
  favouiteTabletOnly: 'favouoite-tablet-only',
  favSelectFill: 'showplan--svg-fill-icon__btnFontColor',
  followSelectFill: 'favourite-unselect-fill-opacity',
  favUnselectFill: 'favourite-unselect-fill-opacity',
  fileName: 'file-name',
  fileSizeInfoBlock: 'file-size-info-block',
  flexItemRight: 'flex-item-right',
  fullWidth: 'width-full',
  fullHeight: 'height-full',
  halfHeight: 'height-half',
  helpBlock: 'help-block',
  HelpSection: 'help-section',
  header: 'header',
  headerSection: 'header-section--bottom-space',
  helpTextParagraph: 'help-text-paragraph',
  iconButton: 'icon-button',
  imageContainer: 'img-container',
  imageUploadAction: 'image-upload-action',
  imageUploadHelpText: 'upload-help-text',
  uploadImageCopyRightText: 'cover-image-copy-right',
  uploadImageLogoCopyRightText: 'logo-image-copy-right',
  profileImageSectionUploadImageCopyRightText: 'profile-image-copy-right',
  imageUploadCopyRightText: 'image-upload-copy-right-text',
  inlineBlock: 'inline-block',
  justifySpaceBetween: 'justify-space-between',
  labelEllipsis: 'label-ellipsis',
  labelInList: 'label-in-list',
  laptopOnly: 'laptop-only',
  lineBreakSmall: 'line-break-small',
  linkButton: 'link-button',
  listItemInput: 'list-item-input',
  listNoStyle: 'list-no-style',
  mobileHidden: 'mobile-hidden',
  mobileOnly: 'mobile-only',
  marginRight: 'margin-right',
  numberCircle: 'number-circle',
  numberCirclePublishDate: 'number-circle-publish-date',
  overlayBelowCarousel: 'overlay-below-carousel',
  overlayIcon: 'overlay-icon',
  overlayErrorImageHelpText: 'overlay-error-image-help-text',
  overlayProductErrorImage: 'overlay-product-error-image',
  pageTitle: 'page-title',
  pageTitleSmall: 'page-title-small',
  pageTitleMargin: 'page-title-margin',
  pipeSeparator: 'pipe-separator',
  primarySection: 'section-primary',
  profileCoverImageContainer: 'profile-cover-image-container',
  profileCoverImageContainerFeatureToggled: 'profile-cover-image-container-feature-toggled',
  profileHeading: 'profile-heading',
  profileImagesContainer: 'profile-images-container',
  profileLogo: 'profile-logo',
  profileLogoContainer: 'profile-logo-container',
  productImagePlaceholderContainer: 'product-image-placeholder-container',
  productDetailsImagePlaceholderContainer: 'product-details-image-placeholder-container',
  profileLogoContainerFeatureToggled: 'profile-logo-container-feature-toggled',
  profileLogoSection: 'profile-logo-section',
  relativePosition: 'relative-position',
  roundCorners: 'round-corners',
  roundAllCorners: 'round-all-corners',
  rowMarginsUnset: 'row-margins-unset',
  secondary00Button: 'btn-secondary',
  secondary01Button: 'btn-secondary-01',
  secondary02Button: 'btn-secondary-02',
  separatedListItem: 'separated-list-item',
  separatedListTitleItem: 'separated-list-title-item',
  sessionTimeFlex: 'session-time-flex',
  sessionInputTime: 'session-input-time',
  sessionContainerBorder: 'session-container-border',
  smallFormGroup: 'small-form-group',
  formGroup: 'form-group',
  socialMediaLogoColor: {
    FACEBOOK: 'facebook-logo-color',
    TWITTER: 'twitter-logo-color',
    YOUTUBE: 'youtube-logo-color',
    LINKEDIN: 'linkedin-logo-color',
    WECHAT: 'wechat-logo-color',
    INSTAGRAM: 'instagram-logo-color'
  },
  square: 'square-container',
  tabletHidden: 'tablet-hidden',
  tabletOnly: 'tablet-only',
  tabletSessionButtonRow: 'tablet-session-button-row',
  tabletSessionCardHeight: 'tablet-session-card-height',
  textCenterMobileOnly: 'text-center-mobile',
  mainStandHolderName: 'main-stand-holder-name',
  mainStandHolderNamePrefix: 'main-stand-holder-name-prefix',
  sharerTitle: 'sharer-title',
  sharerSection: 'sharer-section',
  mshNameSection: 'msh-name-section',
  textUppercase: 'text-uppercase',
  manageFiltersContent: 'manage-filters-content',
  topBottomPadded: 'top-bottom-padded',
  fileTypeSizePadding: 'file-type-size-padding',
  topMargin: 'top-margin',
  topMarginForced: 'top-margin-forced',
  rightPadded: 'right-padded',
  leftPadded: 'left-padded',
  topPadded: 'top-padded',
  leftBottomPadded: 'left-bottom-padded',
  textPrimary: 'text-primary',
  textInfo: 'text-info',
  unsetSessionBackground: 'unset-session-background',
  whiteSpaceNoWrap: 'white-space-no-wrap',
  withoutOpacity: 'without-opacity',
  withOpacity: 'with-opacity',
  zeroMargin: 'zero-margin',
  paginationLoader: 'pagination-loader',
  displayNone: 'display-none',
  displayInitial: 'display-initial',
  floatRight: 'float-right',
  viewAllLinkEllipsis: 'view-all-link-ellipsis',
  circularProgressBar: 'circular-progress-bar',
  circularProgressBarBtn: 'circular-progress-bar-btn',
  exhibitorsCarouselMainDiv: 'exhibitors-carousel-main-div',
  exhibitorsCarouselTitle: 'exhibitors-carousel-title',
  swiperContainer: 'swiper-container',
  goldProductAndDocumentContainer: 'gold-products-document-container',
  silverProductAndDocumentContainer: 'silver-products-document-container',
  productsContainerWithoutCarousel:'products-container-without-carousel',
  documentsContainerWithoutCarousel:'documents-container-without-carousel',
  productsTitleInDirectory:'products-title-in-directory',
  documentsTitleInDirectory:'documents-title-in-directory',
  imgContainer:'image-container',
  swiperSlideContainer: 'swiper-slide-container',
  swiperSlideDescription: 'swiper-slide-description',
  swiperButtonPrev: 'swiper-button-prev',
  controls: "controls",
  swiperButtonNext: 'swiper-button-next',
  swiperPagination: 'swiper-custom-pagination',
  swiperCoverImageContainer: 'swiper-cover-image-container',
  exhibitorsCarouselExhDirectoryBtn: 'exhibitors-carousel-exh-directory-btn',
  exhibitorsCarouselLogo: 'exhibitors-carousel-logo',
  exhibitorsCarouselCoverImg: 'exhibitors-carousel-cover-image',
  exhibitorsCarouselCoverImgGradiant: 'exhibitors-carousel-coverImg-gradiant',
  exhibitorsCarouselLogoImgGradiant: 'exhibitors-carousel-logoImg-gradiant',
  exhibitorFavoriteIcons: 'exhibitors-favorite-icon',
  favIcon: 'fav-icon'
};

const table = {
  borderedTable: 'table table-bordered format-table-sharers'
};

const specific = {
  actionButtonsGroup: 'action-buttons-group',
  accompanyingWebsite: 'accompanying-website',
  address: 'address',
  artistName: 'artist-name',
  artistDescription: 'artist-description',
  brands: 'brands',
  clip: 'innovative-clip',
  customInvitive: 'custom-invitive',
  companyInfo: 'company-info',
  contactOption: 'contact-option',
  contactOptionsContainer: 'contact-options-container',
  contactOptionItem: 'contact-list-item',
  contactOptionsContainerPackageRedesign: 'contact-options-container-package-redesign',
  contactOptionItemPackageRedesign: 'contact-list-item-package-redesign',
  premiumPackageFeatureToggled: 'premium-package',
  coverImage: 'cover-image',
  coverImageContainer: 'cover-image-container',
  coverImageHelpText: 'cover-image-help-text',
  coverImagePlaceholder: 'cover-image-placeholder',
  deleteButtonFloat: 'delete-button-float',
  description: 'description',
  descriptionContainer: 'description-container',
  descriptionContainerMargin: 'description-container-margin',
  detailsHeaderWrapper: 'details-header-wrapper',
  detailsHeader: 'details-header',
  detailsIcon: 'details__icon',
  directoryInfo: 'directory-information',
  directoryItem: 'directory-item',
  directoryItemFeatureToggled: 'directory-item-feature-toggled',
  directoryItemBronze: 'directory-item-bronze',
  directoryItemGold: 'directory-item-gold',
  directoryItemPremiumFeatureToggle: 'directory-item-premium-package-redesign',
  directoryItemGoldBanner: 'directory-item-gold-banner',
  directoryItemGoldBannerFeatureToggle: 'directory-item-gold-banner-package-redesign',
  directoryItemSilver: 'directory-item-silver',
  directoryItemInner: 'directory-item-inner',
  directoryStand: 'directory-stand',
  overflowStandDropdown: 'overflow-stand-dropdown',
  moreStands: 'more-stands',
  documentDetailContainer: 'document-detail-container',
  documentDownloadIcon: 'document-download-icon',
  documentPublishDate: 'document-publish-date',
  downloadExhibitorList: 'download-exhibitor-list',
  downloadEngagementReports: 'download-exhibitor-list',
  dropDownButton: 'drop-button',
  downloadExhibitorPressReleaseList: 'download-exhibitor-press-release-list',
  sendActivationEmailButton: 'send-activation-email-button',
  exhibitorCategory: 'exhibitor-category',
  exhibitorRow: 'exhibitor-row',
  exhibitorInfo: 'exhibitor-info',
  exhibitorInfoFeatureToggle: 'exhibitor-info-package-redesign',
  exhibitorFeature: 'exhibitor-feature',
  features: 'exhibitor-features',
  featureTags: 'feature-tags',
  email: 'email',
  website: 'website',
  exhibitorDetailsDescription: 'exhibitor-details-description',
  exhibitorFeaturesContainer: 'exhibitor-features-container',
  exhibitorFeatureContainer: 'exhibitor-feature-container',
  exhibitorDownloadButton: 'exhibitor-list-download-button',
  matchmakingEditField: 'matchmaking-edit-field',
  loginRolesField: 'login-roles-field',
  matchmakingEditQuestionHelpText: 'matchmaking-edit-question-help-text',
  matchmakingEditQuestionTitleText: 'matchmaking-edit-question-title-text',
  matchmakingQuestionWarning: 'matchmaking-question-warning',
  favouriteLocation: 'favourite-location',
  favouriteLocationFeatureToggled: 'favourite-location-feature-toggled',
  filterBy: 'filter-by-heading',
  filterColumnHeader: 'filter-column-header',
  filterItem: 'filter-item',
  staticItemBlock: 'static-item-block',
  filterItemCheckboxIcon: 'filter-item-checkbox-icon',
  filterItemCheckboxIconSelected: 'filter-item-checkbox-icon-selected',
  filterItemClickable: 'filter-item-clickable',
  flexibleContent: 'flexible-content',
  helpTextBlock: 'help-text-block',
  helpTextSmall: 'help-text-small',
  imageContainer: 'image-container',
  imageUploading: 'image-uploading',
  inlineIcon: 'inline-icon',
  inlineDescription: 'inline-description',
  innovatorFeatureBackground: 'innovator-feature-background',
  logoHelpText: 'logo-help-text',
  logoPlaceholder: 'logo-placeholder',
  marginLeft: 'margin-left',
  newExhibitorFeatureBackground: 'new-exhibitor-feature-background',
  phone: 'phone',
  premiumProductContainer: 'premium-product-container',
  premiumProductContainerBorder: 'premium-product-container-border',
  productTile: 'productContainerTile',
  artistTile: 'artistContainerTile',
  exhibitorTile: 'exhibitor-container-tile',
  exhibitorGridViewRow: 'exhibitor-grid-view-row',
  mainStandHolderLink: 'mainstandholder-link',
  enhancement: 'enhancement',
  enhancedSlider: 'enhanced-slider',
  product: 'product',
  productAddButton: 'product-add-button',
  productAddButtonPlaceholder: 'product-add-button-placeholder',
  productButtonRow: 'product-button-row',
  productButtonsRow: 'product-buttons-row',
  productButtons: 'product-buttons',
  productCarouselArrows: 'product-carousel-arrow',
  nextArrowRighPosition: 'next-arrow-righ-position',
  productTitle: 'product-title',
  producerTitle: 'producer-title',
  line: 'line',
  productTitleContainer: 'product-title-container',
  productContainer: 'product-container',
  productContainerEnhanced: 'product-container-enhanced',
  productContainerViewMode: 'product-container--view',
  productDescription: 'product-description',
  producerDescription: 'producer-description',
  productExhibitorInfo: 'exhibitor-info',
  productExhibitorName: 'exhibitor-name',
  linkedExhibitorName: 'linked-exhibitor-name',
  linkedExhibitorTitle: 'linked-exhibitor-title',
  productPageExhibitorName: 'product-page-exhibitor-name',
  productExhibitorLogo: 'exhibitor-logo',
  productFields: 'product-fields',
  productId: 'product-id',
  productImage: 'product-image',
  productImageContainer: 'product-image-container',
  producerImageContainer: 'producer-image-container',
  productDirectoryImageContainer: 'product-directory-image-container',
  imageInDirectoryContainer: 'image-in-directory-container',
  productImageUploadingPlaceholder: 'product-image-uploading-placeholder',
  productLearnMore: 'learn-more',
  productName: 'product-name',
  productNameContainer: 'product-name-container',
  productNumber: 'product-number',
  productSearch: 'product-searchBox',
  productText: 'product-text',
  productAttributeAnswers: 'product-attribute-answers',
  productAttributeSection: 'product-attribute-section',
  productAttributes: 'product-attributes',
  productInnovativeTag: 'product-innovative-tag',
  products: 'products',
  productsCount: 'products-count',
  productsSection: 'products-section',
  profileCompleteness: 'profile-completeness',
  profileCompletionLabel: 'profile-label',
  profileCompletionProgress: 'progress',
  profileCompletionProgressBlack: 'progress-black',
  productEditGreySecton: 'product-edit-grey',
  productEditContainerBorder: 'product-container-border',
  mandatoryTextAlign: 'mandatory-text-product',
  profileCompletenessParagraphHighlighter: 'profile-completeness-highlighter',
  profileCompletenessHighlighter: 'profile-completeness-field-highlighter',
  twoAndHalfRemMargin: 'twoAndHalfRemMargin',
  twoAndHalfRemMarginTop:'top-and-half-margin-top',
  relativePosition: 'relative-position',
  resizeVertical: 'resize-vertical',
  rightColumnSection: 'right-column-section',
  sectionCard: 'section-card',
  showPlanningCol: 'show-planning-col',
  showTags: 'show-tags',
  showTagsContainer: 'show-tags-container',
  singleLineEllipsis: 'single-line-ellipsis',
  showobjective: 'show-objective',
  socialMedia: 'social-media',
  socialMediaLogo: 'social-media-logo',
  socialMediaLogoContainer: 'social-media-logo-container',
  sectionContainer: 'section-container',
  standContainer: 'stand-container',
  standContent: 'stand-content',
  showPlanContainer: 'show-plan-container',
  standRow: 'stand-row',
  tagItem: 'tag-item',
  tags: 'tags',
  triangle: 'innovative-clip-triangle',
  twoColumnLayout: 'two-column-layout',
  eventEditionLabelMargins: 'with-margins',
  zeroTopMarginLaptop: 'zero-top-margin-in-laptop',
  xsOnly: 'xs-only',
  mdOnly: 'md-only',
  showUtilities: {
    root: 'show-utilities',
    value: 'value',
    category: {
      Badging: 'fa fa-id-card-alt',
      Leads: 'fa fa-user-check',
      Manual: 'fa fa-book',
      Video: 'fa fa-video',
      Meeting: 'fa fa-handshake',
      Diary: 'fa fa-calendar'
    }
  },
  miniParticipantsPanel: 'mini-participants-panel',
  participants: 'participants',
  sponsoredCategory: 'sponsoredCategory',
  filterCategories: 'filterCategories',
  unSelectable: 'unSelectable',
  exhibitorHubActionItemContainer: 'action-item-container',
  exhibitorHubExhibitorInsights: 'exhibitor-insights',
  exhibitorHubActionItemIconWrapper: 'action-item-iconwrapper',
  exhibitorHubActionItemIcon: 'action-item-icon',
  exhibitorHubEmperiaLeadReportIcon: 'emperia-lead-download-icon',
  exhibitorHubActionItemTitle: 'action-item-title',
  exhibitorHubActionItemInformation: 'action-item-information',
  exhibitorHubActionItemTitleH4: 'action-item-titleh4',
  exhibitorHubTaskDescription: 'task-description-padding',
  exhibitorHubFormBackButton: 'form-back-button',
  exhibitorHubFormBackButtonIcon: 'form-back-button-icon',
  exhibitorHubFormDropDown: 'form-drop-down',
  exhibitorHubFormSubmitButtonText: 'form-submit-button-text',
  exhibitorHubOpenLinkIcon: 'open-link-button-icon',
  exhibitorHubOpenLinkButton: 'open-link-button',
  exhibitorHubOpenLinkText: 'open-link-button-text',
  exhibitorHubSimpleTaskText: 'exhibitor-hub-simple-task-text',
  exhibitorHubDownloadIcon: 'download-button-icon',
  exhibitorHubDownloadButton: 'download-button',
  exhibitorHubDownloadText: 'download-button-text',
  exhibitorHubNavigation: 'exhibitor-hub-navigation',
  exhibitorHubManageOffersIcon: 'manage-offers-icon',
  exhibitorInsightsContainer: 'exhibitor-insights-container',
  exhibitorInsightsDescription: 'exhibitor-insights-description',
  exhibitorInsightsLiveNewTextColor: 'exhibitor-insights-live-new-text-color',
  exhibitorInsightsBanner:'exhibitor-insights-banner',
  exhibitorInsightsBannerTitle:'exhibitor-insights-banner-title',
  checkboxChecked: 'fas fa-check-square checkbox-color',
  checkboxUnChecked: 'far fa-square checkbox-color',
  exhibitorHubSelfDeclarationLabel: 'self-declaration-label',
  taskListButton: 'task-list-button',
  viewDashboardButton: 'view-dashboard-button',
  exhibitorTaskListBackButton: 'form-back-button',
  exhibitorTaskListBackButtonIcon: 'form-back-button-icon',
  leadCaptureContainer: 'lead-capture-container',
  leadCaptureBackButtonIcon: 'lead-capture-button-icon',
  leadCaptureBackButton: 'lead-capture-button',
  leadCaptureDownloadReportContainer: 'lead-capture-download-report-container',
  emperiaOnboarding: 'emperia-onboarding',
  emperiaLogin: 'emperia-login',
  emperiaIntroduction: 'emperia-introduction',
  emperiaIntroductionText: 'emperia-introduction-text',
  emperiaLoginText: 'emperia-login-text',
  emperiaLoginCredentials: 'emperia-login-credentials',
  emperiaTileErrorIcon: 'emperia-tile-error-icon',
  appDownload: 'app-download',
  appUrlImage: 'app-url-image',
  leadCaptureTitle: 'lead-capture-title',
  leadCaptureDownloadButtonRow: 'lead-capture-download-button-row',
  leadCaptureDownloadButton: 'lead-capture-download-button',
  horizontalLine: 'horizontal-line',
  taskListButtonContainer: 'tasklist-btn-container',
  viewDashboardButtonContainer: 'view-dashboard-btn-container',
  taskListTooltip: 'tasklist-tooltip',
  activeIndicator: 'active-indicator',
  exhibitorName: 'exhibitor-name',
  errorContainer: 'error-container',
  standaloneProductContainer: 'standalone-product-container',
  productExtraCharacteristics:'product-extra-characteristics',
  navigationBack: 'navigation-back',
  otherProducts: 'other-products',
  otherProductsTitle: 'other-products-title',
  productCarouselItem: 'product-carousel-item',
  productCarouselImageContainer: 'product-carousel-image-container',
  exhibitorDirectoryImageContainer: 'exhibitor-directory-image-container',
  connectExhibitor: 'connect-exhibitor',
  standName: 'stand-name',
  applicableForSection: 'applicable-for-section',
  artistDirectoryImageContainer: 'artist-directory-image-container',
  paddingZero: 'padding-zero',
  artistsLinkList: 'artists-link-list',
  artistsLink: 'artists-link',
  disabledTile: 'disabled-tile',
  loadSpinner: 'load-spinner',
  companyAdminTextEllipsis: 'company-admin-text-ellipsis',
  copyRightContainer: 'copy-right-container',
  copyRightText: 'copy-right-text',
  videoOverlayBlur: 'video-overlay-blur',
  videoOverlay: 'video-overlay',
  content: 'content',
  actions: 'actions',
  toggleButton: 'toggle-button',
  videoThumbnail: 'video-thumbnail',
  productVideoThumbnailPlay: 'product-video-play-button',
  videoPlayIcon: 'fa fa-play-circle',
  videoOverlayCloseIcon: 'fa fa-close',
  videoOverlayCloseText: 'close-text',
  callToActiondetails: 'callToAction-details-info',
  calltoActionDetailsMessageInfo: 'callToAction-details-message-info',
  calltoActionDetailsMessageHighlight: 'callToAction-details-message-highlight',
  calltoActionDetailsMessageError: 'callToAction-details-message-error',
  productCreditsMessageHighlight :'product-credits-message-highlight',
  productCreditsMessageInfo:'product-credits-message-info',
  addProductCredits:'add-products-credits',
  addnewProducts:'add-new-products',
  prePopulateTextStatus:'prepopulate-text-status',
  prePopulateBtn:'prepopulate-btn',
  prePopulateSpinner:'prepopulate-spinner',
  addnewProductsDisabled:'add-new-products-disabled',
  productCountWithEntitlement: 'products-count-with-entitlement',
  selfUpgradeSharerLegend:'self-upgrade-sharer-legend',
  completeProfileButton: 'complete-profile-btn',
  circularProgressBarText: 'circular-progress-bar-text',
  circularProgressBarHelpText: 'circular-progress-bar-help-text',
  reportButtonCaretDown: 'report-button-caret-down',
  documentPrePopulation: 'document-pre-population',
  exhibitorDirectoryModal: 'exhibitor-directory-modal',
  modalProductName: 'modal-product-name',
  modalProductDescription: 'modal-product-description',
  modalLinks: 'modal-links',
  modalCloseButton: 'modal-close-button',
  pdfPager: 'pdf-pager',
  pdfDocument: 'pdf-document',
  pdfContainer: 'pdf-container',
  documentThumbnail: 'document-thumbnail',
  premiumExhibitorCarouselClip: 'premium-exhibitor-carousel-clip',
  premiumExhibitorCarouselClipText: 'premium-exhibitor-carousel-clip-text',
  modalClipText: 'modal-clip-text',
  documentErrorSection: 'document-error-section',
  exhibitorDetailContainer: 'exh-detail-container',
  logoStandContainer: 'logo-stand-container',
  exhibitorSummary: 'exhibitor-summary',
  exhibitorContactContainer: 'exhibitor-contact-container',
  tagIcon: 'tag-icon',
  ppsTags: 'pps-tags',
  titleInExhibitorSummary: 'title-in-exhibitor-summary',
  bronzeExhibitorCategory: 'bronze-exhibitor-category',
};

const selenium = {
  dataLoaded: 'data-loaded'
};

const modal = {
  modalBackDrop: 'modal-backdrop fade in',
  leadCapture: {
    header: 'header-lead-capture-form',
    back: 'back-lead-capture-form'
  },
  acknowledgement: {
    popup: 'modal popup',
    content: 'content',
    greeting: 'greeting',
    line: 'line',
    message: 'message',
    done: 'done'
  }
};

const discover = {
  productDirectory: {
    discoverBanner: 'directory-discover-banner',
    productCount: 'discover-product-count',
    discoverIcon: 'directory-discover-icon',
    navigationFlexbox: 'navigation-flexbox',
    discoverProductImage: 'discover-product-image',
    discoverProductName: 'discover-product-name',
    productItem: 'discover-product-item',
    certificationBox: 'certifications-box',
    certificationImage: 'certification-image',
    certificationSustainabilityText: 'certification-sustainability-text',
    browseSearchTextBox: 'browse-search-text-box',
    browseSearchText: 'browse-search-text',
    searchDiscoverButton: 'search-discover-button',
    browseSearchTextBoxLink: 'browse-search-text-box-link'
  },
  productDetails: {
    productFields: 'product-discover-fields',
    discoverIcon: 'discover-icon',
    discoverNavigationLink: 'discover-navigation-link'
  }
}

const contactForm = {
  successPage:{
    successBox:'success-box',
    successMessage:'success-message',
    successText:'success-text',
    successExhibitorName:'success-exhibitor-name',
    successProductName:'success-product-name',
    successExhibitorList:'success-exhibitor-list'
  },
  contactForm:{
    organisationName: 'exh-organisation-name',
    productName: 'product-name',
    headerBox: 'header-box',
    requestFormTitle: 'request-form-title',
    requestFormDescription: 'request-form-description',
    requestFormExhibitors: 'request-form-exhibitors',
    requestFormExhibitorsDeleteIcon: 'request-form-exhibitors-delete-icon',
  }
}

const bulkRequestQuoteFloatingButton = {
    bulkRequestQuoteButton: 'bulk-request-quote-button',
    bulkRequestQuoteBubble: 'bulk-request-quote-button-bubble',
    bulkRequestQuoteFloatingArea: 'bulk-request-quote-floating-area',
    bulkRequestQuoteClearAllButton: 'bulk-request-quote-clear-all-button',
    bulkRequestQuoteClearAllButtonText: 'bulk-request-quote-clear-all-button-text',
  }

const theme = {
  primaryBackgroundColor: 'theme__ac-color--background-color',
  primaryButtonTextColor: 'theme__btn-text--color',
  primaryBorderColor: 'theme__ac-color--border-color'
};

const Classes = {
  icons,
  circleSpinner,
  ...bootstrap,
  ...icons,
  ...forms,
  ...switchControl,
  ...generic,
  ...specific,
  ...selenium,
  ...table,
  ...modal,
  ...theme,
  ...contactForm,
  ...discover,
  ...bulkRequestQuoteFloatingButton
};

export default Classes;
