import React, {useEffect, useRef, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from 'react-bootstrap/lib/Button';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Navigation, Pagination, Autoplay} from 'swiper/modules';
import ExhibitingOrganisationService from '../common/api/ExhibitingOrganisationService';
import EventEditionFeatureConfigService from '../profile/api/EventEdititonFeatureConfigService';
import EventEditionService from '../common/api/EventEditionService';
import {DataDtmActions, Packages} from '../common/Constants';
import TranslationService from '../translations';
import ErrorContainer from '../common/components/ErrorContainer';
import Utils from '../common/Utils'
import { Devices, ImageSizes } from '../common/Constants';
import StringFormatter from '../common/StringFormatter';
import Classes from '../common/Classes';
import dtmTags from '../common/dtmTags';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../../styles/common.less';
import mixpanelConstants from "../common/mixpanelConstants";
import Tracker from "../common/Tracker";
import {trackMixpanelEvent} from "../common/MixpanelHelper";

const ExhibitorsCarousel = ({ baseApiUrl, context, assets, navigation, themes }) => {

  const [exhibitors, setExhibitors] = useState([]);
  const [hideStandInfo, setHideStandInfo] = useState([]);
  const [errors, setErrors] = useState([]);
  const [showExhibitorListButton, setShowExhibitorListButton] = useState(false);
  const { interfaceLocale, primaryLocale, eventEditionId} = context;
  const translations = TranslationService.getTranslations(interfaceLocale);
  const {defaultOrganisationLogoUrl, defaultExhibitingOrganisationCoverUrl} = assets;
  let tracker = Tracker.getTracker();
  const swiperRef = useRef(null);
  const isAutoplaying = useRef(true);
  const autoplayCounter = useRef(0);

  useEffect(() => {
    function fetchExhibitors() {
      ExhibitingOrganisationService.getExhibitorsByEventEditionAndPackageType(baseApiUrl, eventEditionId, Packages.gold, 10, interfaceLocale, primaryLocale)
      .then(res => {
        if (Utils.serviceResultHasError(res)) {
          setErrors(res.errors)
          return;
        }
        setExhibitors(res.exhibitingOrganisationByPackageType)
      });

      EventEditionFeatureConfigService.isHideStandInfoFeatureEnabled(baseApiUrl, eventEditionId, interfaceLocale)
        .then((isEnabled) => {
          setHideStandInfo(isEnabled);
        })

      EventEditionService.getEventEditionBusinessUnit(baseApiUrl, eventEditionId)
          .then(eventEdition => {
            // get businessUnit
            const isGermany = eventEdition.businessUnitName?.toUpperCase() === "GERMANY"
                || eventEdition.businessUnitGbsCode?.toUpperCase() === "OBU_015";
            setShowExhibitorListButton(!isGermany)
          });
    }

    fetchExhibitors()
    let timer;
    let props = {
      packageName: Utils.getPackageName(3),
      component: mixpanelConstants.component.homePage
    };
    const initializeSwiper = () => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.on('slideChange', () => {
          isAutoplaying.current = false;
        })
        swiperRef.current.swiper.on('autoplay', () => {
          isAutoplaying.current = true;
        })
        const swiperInstance = swiperRef.current.swiper;
        const observer = trackExhibitorImpressions(timer, props, swiperInstance);
        swiperInstance.slides.forEach((slide) => {
          observer.observe(slide);
        })
        const slideObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting) {
              autoplayCounter.current = 0
              isAutoplaying.current = false
            } else {
              isAutoplaying.current = true
            }
          })
        }, {threshold: 1})
        slideObserver.observe(swiperRef.current);
        return () => {
          observer.disconnect();
          slideObserver.disconnect();
        };
      } else {
        setTimeout(initializeSwiper, 100);
      }
    }
    initializeSwiper();
  }, []);

  const trackExhibitorImpressions = (timer, props, swiperInstance) => {
    return new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          timer = setTimeout(() => {
            const otherProps = JSON.parse(entry.target.getAttribute('mixpanel-props'));
            if (otherProps) {
              props = {...props, ...otherProps};
            }
            if (!isAutoplaying.current ||
                (isAutoplaying.current && autoplayCounter.current >= 0 && autoplayCounter.current < swiperInstance.slides.length)) {
              trackMixpanelEvent(mixpanelConstants.eventName.exhibitorImpression, props, tracker);
              if (isAutoplaying.current) {
                autoplayCounter.current = autoplayCounter.current + 1;
                if(autoplayCounter.current === swiperInstance.slides.length)
                  autoplayCounter.current = -1;
              }
              if(!isAutoplaying.current && autoplayCounter.current !== -1)
                autoplayCounter.current = 0
            }
          }, 5000);
        } else {
          clearTimeout(timer);
        }
      });
    }, {
      threshold: 1
    });
  }

    const getCoverImage = (coverImageUrl)=>{
      if(!_.isEmpty(coverImageUrl)) {
        return (<img className={`${Classes.exhibitorsCarouselCoverImg} img-responsive`} src={Utils.getResizedImage(coverImageUrl, Devices.desktop, ImageSizes.coverImage)} />)
      }
      else if(!_.isEmpty(defaultExhibitingOrganisationCoverUrl)) {
        return (<img className='img-responsive' src={defaultExhibitingOrganisationCoverUrl}/>)
      }
      else{
        return (<div className={Classes.exhibitorsCarouselCoverImgGradiant} style={{ backgroundImage: `linear-gradient(${themes.brandPrimary}, white)`}} />)
      }
    }

    const getLogoImage = (logoImage)=>{
      if(!_.isEmpty(logoImage)) {
        return (<img className={`${Classes.exhibitorsCarouselLogo} img-responsive`} src={logoImage}/>)
      }
      else if(!_.isEmpty(defaultOrganisationLogoUrl)) {
        return (<img className={`${Classes.exhibitorsCarouselLogo} img-responsive`} src={defaultOrganisationLogoUrl}/>)
      }
      else{
        return (<div className={Classes.exhibitorsCarouselLogoImgGradiant} style={{backgroundImage: `linear-gradient(${themes.brandPrimary}, white)`}} />)
      }
    }
    return exhibitors.length > 0 ?
    (
      <div className={Classes.exhibitorsCarouselMainDiv}>
      <ErrorContainer errors={errors} />
      <h2 className={Classes.exhibitorsCarouselTitle}>{translations.exhibitorCarousel.title}</h2>
      <div className={Classes.swiperContainer}>
      <Swiper pagination= {{ el: `.${Classes.swiperPagination}`, renderBullet: function () {
                return `<span class="swiper-pagination-bullet" style="background: ${themes.brandPrimary}"></span>`;
              }, clickable: true }}
              navigation={{ nextEl: `.${Classes.swiperButtonNext}`, prevEl: `.${Classes.swiperButtonPrev}`}}
              autoplay={{ delay: 10000, disableOnInteraction: false }} loop={false}
              modules={[Pagination, Navigation, Autoplay]}
              ref={swiperRef}
      >
            {
              exhibitors.map((x, index) =>
                (
                    <SwiperSlide key={x.id} data-swiper-slide-index={index}
                                 mixpanel-props={JSON.stringify({
                                   exhibitorId: x.id,
                                   exhibitorName: x.name
                                 })}>
                        <div
                            data-dtm={dtmTags.generic.exhibitorsGoldCarouselItem}
                            data-dtm-attributes={JSON.stringify({exhibitorId: x.id})}
                            data-dtm-actions={DataDtmActions.view}
                            onClick={() => trackMixpanelEvent(mixpanelConstants.eventName.exhibitorClicked, {exhibitorId: x.id, exhibitorName: x.name, packageName: Utils.getPackageName(3), element: mixpanelConstants.element.goldCarousel,
                                component: mixpanelConstants.component.homePage}, tracker
                            )}
                        >
                            <a href={StringFormatter.format(navigation.exhibitorPublicDetailsUrlFormat, x.organisationGuid)}
                               data-dtm={dtmTags.generic.exhibitorsGoldCarouselDetailsLink}
                               data-dtm-attributes={JSON.stringify({exhibitorId: x.id})}>
                                <div className={`${Classes.swiperCoverImageContainer} hidden-xs`}>
                                    {
                                        getCoverImage(x.coverImage)
                                    }
                                </div>
                                <div className='hidden-sm hidden-md hidden-lg hidden-xl'>
                                    {
                                        getLogoImage(x.logo)
                                    }
                                </div>
                                <div className={Classes.swiperSlideContainer}>
                                    <h3>
                                        {x.name}
                                    </h3>
                                    <p className={Classes.swiperSlideDescription}>
                                        {x.description}
                                    </p>
                                    {!_.isEmpty(x.stands) && !hideStandInfo ?
                                        (<div>
                                            <strong>{translations.exhibitorCarousel.stands}</strong> {x.stands}
                                        </div>)
                                        : null
                                    }
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
        <div className={`${Classes.swiperButtonPrev} hidden-xs`}></div>
        <div className={`${Classes.swiperButtonNext} hidden-xs`}></div>
      </div>
      <div className={Classes.swiperPagination}></div>
      {showExhibitorListButton ? <a href={StringFormatter.format(navigation.exhibitorPublicDirectoryUrlFormat, false, interfaceLocale, eventEditionId)} 
         data-dtm={dtmTags.generic.exhibitorsGoldCarouselDirectoryButton} 
         data-dtm-attributes={JSON.stringify({ eventEditionId })}>
          <Button className={`btn btn-primary ${Classes.exhibitorsCarouselExhDirectoryBtn}`}>{translations.exhibitorCarousel.exhibitorDirectoryButtonText}</Button>
      </a> : null}
      </div>
    ) : null;
}

ExhibitorsCarousel.propTypes = {
  baseApiUrl: PropTypes.string.isRequired,
  context: PropTypes.shape({
    interfaceLocale: PropTypes.string.isRequired,
    eventEditionId: PropTypes.string.isRequired
  }).isRequired,
  assets: PropTypes.shape({
    defaultExhibitingOrganisationCoverUrl: PropTypes.string,
    defaultOrganisationLogoUrl: PropTypes.string
  }),
  navigation: PropTypes.shape({
    exhibitorPublicDetailsUrlFormat: PropTypes.string.isRequired,
    exhibitorPublicDirectoryUrlFormat: PropTypes.string.isRequired
  }),
  themes:PropTypes.shape({
    brandPrimary: PropTypes.string.isRequired
  })
}

export default ExhibitorsCarousel;
