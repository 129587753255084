import Request from '../../common/api/request';
import ServiceResultFactory from '../../common/api/ServiceResultFactory';

const getEventEditionFeatureConfig = (baseUrl, eventEditionId, locale) => {
  const query = `{
    eventEditionFeatureConfigs(eventEditionId:"${eventEditionId}")
      {
        exhibitorFeatures
      }
    }`;

  return Request.graphqlRequest(`${baseUrl}/graphql`, query)

    .then(response => ServiceResultFactory.fromGraphqlSuccess(response, locale, 'eventEditionFeatureConfigs'))
    .catch(result =>
      ServiceResultFactory.fromError(locale, result, false));
};

const toggleHideStandInfo = (baseUrl, eventEditionId, hideStandInfo, locale) => {
  const mutation = `
  mutation{
          toggleHideStandInfoFeature(
            eventEditionId:"${eventEditionId}"
                toggleHideStandInfo: ${hideStandInfo}
                )
            }`;

  return Request
      .graphqlRequest(`${baseUrl}/graphql`, mutation, { })
      .catch(result => ServiceResultFactory.fromError(locale, result, true));
};

const isArtistsAndGalleriesEventEdition = (baseUrl, eventEditionId, locale) => getEventEditionFeatureConfig(baseUrl, eventEditionId, locale)
  .then(response => response.exhibitorFeatures.includes('ArtistsAndGalleries'));

const isProductDirectoryNavigationFeatureEnabled = (baseUrl, eventEditionId, locale) => getEventEditionFeatureConfig(baseUrl, eventEditionId, locale)
  .then(response => response.exhibitorFeatures.includes('ProductDirectoryNavigation'));

const isHideStandInfoFeatureEnabled = (baseUrl, eventEditionId, locale) => getEventEditionFeatureConfig(baseUrl, eventEditionId, locale)
    .then(response => response.exhibitorFeatures.includes('HideStandInfo'));

export default {
  getEventEditionFeatureConfig, toggleHideStandInfo, isArtistsAndGalleriesEventEdition, isProductDirectoryNavigationFeatureEnabled, isHideStandInfoFeatureEnabled
};
